import {PureComponent} from "react";

import './index.css';
class Icp extends PureComponent{


    render() {

        return (<div className="icp-container">

            <div>版权所有 © 桐柏宫  浙ICP备14025109号-7</div>


        </div>);
    }
}

export  default Icp;
