import {createRef, PureComponent} from "react";
import {Card, Space,} from "antd-mobile"
import '@nutui/nutui-react/dist/style.css'

import {ArcElement, Chart as ChartJS, Legend, LineElement, PointElement, RadialLinearScale, Tooltip,} from 'chart.js';
import ReactECharts from 'echarts-for-react';
import '../../index.css'

import './index.css';

import {Animate, Button, Form, Image, Input, Radio, Row, Toast,} from '@nutui/nutui-react'
//for mock
// import jsonData from './mock.json';
// import goodsData from './goods.json';

// const loadData = JSON.parse(JSON.stringify(jsonData));
// const loadGoods = JSON.parse(JSON.stringify(goodsData));
ChartJS.register(ArcElement, LineElement, Tooltip, Legend, RadialLinearScale, PointElement);


// const DATE_PICKER_DEFAULT = "日期选择";
const FIVE_ELEMENTS = ['金', '木', '水', '火', '土'];
// const FIVE_ELEMENTS_Icons=['金', '木', '水', '火', '土'];
const BASE = "https://wtwt.cn/product-category/"
const FIVE_ELEMENTS_Buy_Urls = [
    BASE + "jin",
    BASE + "mu",
    BASE + "shui",
    BASE + "huo",
    BASE + "tu",
];


class Luck extends PureComponent {

    state = {
        showForm: true,
        disableForm: false,
        showLuck: false,
        showLuckData: false,
        data: {},
        gender: "",
        luckDirection: "",
        luckColor: "",
        luckNum: "",
        name: "",
        datePickerVisible: false,
        birthdate: "",
        birthdateInitValue: "",
        wuxing_summary_string: "",
        wuxing_missing: "",
        wuxing_missing_code:"",
        dateRef: null,
        isDataOk: false,
        goodsItems:{},
        caiyun:[],
        pingan:[],
        zhihui:[]

    };

    inputRef = createRef();


    showDatePicker = () => {
        // console.log("chen:" + this.state.datePickerVisible);
        this.setState({datePickerVisible: true});

    }
    hideDatePicker = () => {
        this.setState({datePickerVisible: false});

    }

    componentDidMount() {
        // const that = this;
        // setTimeout(() => {
        //     this.parseData(loadData, that);
        //     // var date4 = window. ruiDatepicker().init('#demo4');
        // }, 2000);
        const input = this.inputRef.current;

        // 添加值变更监听器
        input.addEventListener('input', this.handleInputChange);
    }

    getOption = () => {

        const values = this.state.wuxing.map(it => it.value);
        const colors = this.state.wuxing.map(it => it.color);
        // console.log(values);
        return {
            series: [
                {
                    name: '',
                    type: 'pie',
                    color: colors,
                    center: ['50%', '50%'],
                    radius: ['60%', '90%'],
                    data: values,
                    emphasis: {
                        show: false,
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    label: {
                        show: true,
                        position: 'outside'
                    },
                    labelLine: {
                        show: false  // 隐藏标签线
                    },
                }
            ]
        };
    }

    handleInputChange = (event) => {
        this.setState({birthdateInitValue: event.target.value})
        // 处理值变更的逻辑
        this.checkData();
    }

    componentWillUnmount() {
        // 组件卸载前，获取 ref 当前的 DOM 元素
        const input = this.inputRef.current;


        try {
            // 移除值变更监听器
            input.removeEventListener('input', this.handleInputChange);
        } catch (e) {

        }
    }

    onChange = (e) => {
        this.setState({gender: e.target.value});
    };

    datePickConfirm = (values, options, that) => {
        console.log("chen" + JSON.stringify(values))
        // const date = values.slice(0, 3).join('-')
        // const time = values.slice(3).join(':')
        // const dd = `${date}T${time}`;
        // that.setState({birthdate: dd, birthdateInitValue: dd})
    }


    parseData(data, that) {
        // console.log(data)
        const fortune_result = data.fortune_result;
        const direction = fortune_result.今天详细情况[2];
        const color = fortune_result.今天详细情况[5];
        const nums = fortune_result.今天详细情况[6];
        const caiyun = fortune_result.财运五行用神[0];
        const pingan = fortune_result.平安五行用神[0];
        const zhihui = fortune_result.智慧五行用神[0];



        let dataRes = {};
        dataRes.gan_year = fortune_result.年柱[0];
        dataRes.gan_month = fortune_result.月柱[0];
        dataRes.gan_day = fortune_result.日柱[0];
        dataRes.gan_hour = fortune_result.时柱[0];
        dataRes.zhi_year = fortune_result.年柱[1];
        dataRes.zhi_month = fortune_result.月柱[1];
        dataRes.zhi_day = fortune_result.日柱[1];
        dataRes.zhi_hour = fortune_result.时柱[1];
        dataRes.canggan_year = fortune_result.年柱[2];
        dataRes.canggan_month = fortune_result.月柱[2];
        dataRes.canggan_day = fortune_result.日柱[2];
        dataRes.canggan_hour = fortune_result.时柱[2];
        dataRes.sheng_year = fortune_result.年柱[3];
        dataRes.sheng_month = fortune_result.月柱[3];
        dataRes.sheng_day = fortune_result.日柱[3];
        dataRes.sheng_hour = fortune_result.时柱[3];
        // 从数据中提取年份和运气得分
        // const datat = {
        //     "事业": fortune_result.今年详细情况,
        //     "去年": fortune_result.去年详细情况,
        //     "明年": fortune_result.明年详细情况,
        // };
        // const years = ["正月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "腊月"];
        // const healthy_scores = datat["去年"].map(item => typeof item[2] === 'number' ? item[2] : null).filter(item => item);
        // const shiye_scores = datat["事业"].map(item => typeof item[2] === 'number' ? item[2] : null).filter(item => item);
        // const huying_scores = datat["明年"].map(item => typeof item[2] === 'number' ? item[2] : null).filter(item => item);

        const wuxing_scores = fortune_result.五行 ?? [];
        let wuxing_total_score = fortune_result.今天详细情况[1];
        const FIVE_ELEMENTS = ['金', '木', '水', '火', '土'];
        const ELEMENT_MAP = [
            {"code": "50",name:"jin"},
            {"code": "51",name:"mu"},
            {"code": "54",name:"shui"},
            {"code": "52",name:"huo"},
            {"code": "53",name:"tu"},
        ];
        // jin=50
        // mu=51
        // huo=52
        // tu=53
        // shui=54
        const FIVE_COLORS = ['#F2B846', '#79A164', '#407DD4', '#DE5446', '#A68751'];
        let wuxing_summary = [];
        let wuxing_summary_string = "";
        let wuxing_missing = "";

        let wuxing_missing_request_code = "";
        let wuxing_missing_code = "";

        if (wuxing_scores.length > 0) {
            // console.log("chenzhen:"+wuxing_scores);
            for (let i = 0; i < FIVE_ELEMENTS.length; i++) {
                wuxing_summary.push({
                    name: FIVE_ELEMENTS[i],
                    value: parseInt(wuxing_scores[i] * 10),
                    color: FIVE_COLORS[i]
                });
            }
            wuxing_summary.sort((a, b) => {
                return b.value - a.value;
            });
            wuxing_missing = wuxing_summary[4].name;
            let elementmapElement = ELEMENT_MAP[FIVE_ELEMENTS.indexOf(wuxing_missing)];
            wuxing_missing_request_code = elementmapElement.code;
            wuxing_missing_code = elementmapElement.name;
            wuxing_summary_string = `五行缺${wuxing_missing}，推荐补${wuxing_missing}属性饰品。`;

        }

        const radarData = {
            labels: ['金', '木', '水', '火', '土'], datasets: [{
                label: '五行得分', data: wuxing_scores, backgroundColor: 'rgba(255, 255, 0, 0.2)', // 阴影部分颜色设置为黄色，透明度20%
                borderColor: 'rgba(255, 153, 51, 1)', // 边框颜色
                borderWidth: 1, pointBackgroundColor: 'rgba(255, 153, 51, 1)', // 数据点背景颜色
                pointBorderColor: '#fff', pointHoverRadius: 5,
            }]
        };


        const radarConfig =
            {
                elements: {
                    line: {
                        borderWidth: 1
                    }
                },
                scales: {
                    r: {
                        pointLabels: {
                            font: {
                                size: 20
                            }
                        }
                    }
                }
            }
        ;

        const s = parseInt(wuxing_total_score, 10);
        // console.log(data.sumScore)
        const ds = -1.2 * (100 - s) - 20

        that.setState({
            data: dataRes,
            showLuck: false,
            showLuckData: true,
            showForm: false,
            disableForm: true,
            birthdate: "",
            luckDirection: direction,
            luckColor: color,
            luckNum: nums,
            // chartData: chartData,
            // chartConfig: config,
            radarData: radarData,
            radarConfig: radarConfig,
            wuxing_summary_string,
            wuxing_missing,
            sumScore: wuxing_total_score,
            wuxing: wuxing_summary,
            score: ds,
            displayScore: s,
            wuxing_missing_code,
            caiyun,
            pingan,
            zhihui
        })

        this.fetchMissingElementGoods(wuxing_missing_request_code);

    }

    async fetchMissingElementGoods(e) {

        const that = this;
        const b64Code=btoa("ck_933931726065798e2c122d94b1bf78afaeb1a749:cs_e5a634c8cee71c04657ce41ebdce29a2db5880b8");
        try {
            // request
            fetch(`https://wtwt.cn/wp-json/wc/v3/products/categories/${e}`, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${b64Code}`,

                },
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.hasOwnProperty("code")) {
                        that.setState({goodsItems:data})
                    }

                });
        } catch (error) {
            console.error(error.message);
        }
    }


    render() {
        return <div>
            <div className={this.state.showLuckData?"video-background2":"video-background"}>
                {/*<video className="video" autoPlay muted={true} loop={true}>*/}
                {/*    <source src="https://ssswork.oss-cn-hangzhou.aliyuncs.com/qq.MOV" type="video/mp4"/>*/}
                {/*    Your browser does not support the video tag.*/}
                {/*</video>*/}
            </div>
            <div className="main">
                <div className="main-title" >今日运势</div>
                <section className="main-visual" style={{justifyContent: this.state.showLuckData ? "start" : "center"}}
                         id={"calculations-container"}>

                    <div className="overlay">
                        {/*{this.renderTopCard()}*/}

                        {!this.state.showLuckData&&this.renderForm()}

                        {this.state.showLuckData && this.renderDetail()}
                    </div>
                </section>
            </div>

        </div>;
    }

    checkData = () => {
        // console.log(this.state);

        setTimeout(() => {
            // console.log(this.state);

            const dateString = this.inputRef.current.value;
            // console.log(dateString);
            if (dateString === "") {
                this.setOkFalse();
                return;
            }


            if (this.state.name === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.gender === "") {
                this.setOkFalse();
                return;
            }

            this.setState({
                isDataOk: true
            });


        }, 100);

    }

    setOkFalse() {
        if (this.state.isDataOk) {
            this.setState({isDataOk: false});
        }
    }

    renderAnalysis() {
        if (Object.keys(this.state.data).length === 0) {
            return <div></div>
        }
        const d = this.state.data;

        const gridData = [
            [{label: "宫位", color: "#000"}, {label: "年柱", color: "#000"}, {
                label: "月柱",
                color: "#000"
            }, {label: "日柱", color: "#000"}, {label: "时柱", color: "#000"},],
            [{
                label: "十神",
                color: "#000"
            }, {label: d.sheng_year, color: "#dc2626"}, {label: d.sheng_month, color: "#dc2626"}, {
                label: d.sheng_day,
                color: "#dc2626"
            }, {label: d.sheng_hour, color: "#dc2626"},],
            [{label: "天干", color: "#000"}, {
                label: d.gan_year,
                color: "#16a343"
            }, {label: d.gan_month, color: "#16a343"}, {label: d.gan_day, color: "#16a343"}, {
                label: d.gan_hour,
                color: "#16a343"
            },],

            [{label: "地支", color: "#000"}, {label: d.zhi_year, color: "#2563eb"}, {
                label: d.zhi_month,
                color: "#2563eb"
            }, {label: d.zhi_day, color: "#2563eb"}, {label: d.zhi_hour, color: "#2563eb"},], [{
                label: "藏干",
                color: "#000"
            }, {label: d.canggan_year, color: "#ca8a04"}, {label: d.canggan_month, color: "#ca8a04"}, {
                label: d.canggan_day,
                color: "#ca8a04"
            }, {label: d.canggan_hour, color: "#ca8a04"},],

        ];

        let buyIndex = -1;
        let buyUrl = "";

        if (this.state.wuxing_missing !== "") {
            buyIndex = FIVE_ELEMENTS.indexOf(this.state.wuxing_missing);
            buyUrl = FIVE_ELEMENTS_Buy_Urls[buyIndex];
            // console.log(buyUrl)
        }

        return this.wrapperCard(<div>
            <div id="analysis-results" className="text-white">

                {/*<h2 className="luck-title">十神旺衰</h2>*/}
                {/*<div className="chart">*/}
                {/*    <Pie data={this.state.chartData} options={this.state.chartConfig}/>*/}
                {/*    /!*<canvas id="pieChart" width="300" height="300"></canvas>*!/*/}
                {/*</div>*/}

                <h2 className="luck-title">五行旺衰</h2>
                {/*<div className="chart">*/}
                {/*    <Radar data={this.state.radarData} options={this.state.radarConfig}/>*/}
                {/*    /!*<canvas id="radarChart" width="300" height="300"></canvas>*!/*/}
                {/*</div>*/}

                <div className="text-wuxing-summary">
                    {this.state.wuxing_summary_string}
                </div>


                <Space height={40}/>

                {/*<div className="report-section pillar-analysis">*/}
                {/*    <h2 className="luck-title">八字命盘解析</h2>*/}
                {/*    <div>*/}
                {/*        {gridData.map(it =>*/}
                {/*            <div className="row-react">*/}

                {/*                <div></div>*/}
                {/*                {it.map((its, index) => <div className="font-bold"*/}
                {/*                                             style={{*/}
                {/*                                                 color: its.color,*/}
                {/*                                                 flex: 1,*/}
                {/*                                                 margin: 8,*/}
                {/*                                                 backgroundColor: "#0000001A"*/}
                {/*                                             }}>{its.label}</div>)}*/}
                {/*            </div>)}*/}


                {/*    </div>*/}

                {/*</div>*/}
            </div>


            {/*<div className="index-xuan__box"*/}
            {/*     >*/}
            {/*    <img src={xuan1} className="index-xuan__pic1"/>*/}
            {/*        <img src={xuan2} className="index-xuan__pic2"/>*/}

            {/*</div>*/}

            {
                buyIndex !== -1 && <div onClick={() =>
                    window.open(buyUrl, '_blank', 'noopener,noreferrer')}

                >
                    <Animate type="breath" loop>
                        <Button className="text-wuxing-buy" type="primary">立刻获得护身符</Button>
                    </Animate>
                    <Row>
                        <Image
                            src="https://wtwt.cn/wp-content/uploads/2024/08/20220821025922538.png"
                            fit="contain"
                            style={{marginRight: '10px'}}
                            onError={() => {
                                console.log('image error')
                            }}
                        />
                    </Row>


                </div>
            }


        </div>, 360, 100)
    }

    submitFailed = (error) => {
        Toast.show({content: JSON.stringify(error), icon: 'fail'})
    }

    submitSucceed = (values) => {
        // console.log(values);


        if (!this.state.isDataOk) return;
        // if (values === undefined
        //     // || this.state.birthdate === DATE_PICKER_DEFAULT
        //     // || Object.keys(values).length !== 2
        // ) {
        //     Toast.show({ content: "\"请输入个人信息\"", icon: 'fail' })
        //     return;
        // }

        const userName = this.state.name;
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (!reg.test(userName)) {
            Toast.show({content: "\"请输入正确的姓名\"", icon: 'fail'})
            return;
        }

        // console.log(this.inputRef.current.value);
        const dateString = this.inputRef.current.value;
        let dateParam = "";
        if (dateString === "") {
            Toast.show({content: "\"请输入生日\"", icon: 'fail'})
            return;
        }
        // console.log(this.inputRef.current);
        // console.log(this.inputRef.current.getAttribute("data-date"));
        const dreal = this.inputRef.current.getAttribute("data-date");
        const darr = dreal.split("-");
        const y = darr[0].padStart(2, '0');
        const m = darr[1].padStart(2, '0');
        const d = darr[2].padStart(2, '0');

        const h = this.inputRef.current.getAttribute("data-hour").padStart(2, '0');
        const min = this.inputRef.current.getAttribute("data-minute").padStart(2, '0');

        dateParam = `${y}-${m}-${d}T${h === "-1" ? "00" : h}:${min}`;
        // console.log(dateParam);
        this.setState({
            birthdate: dateParam
        })


        const gender = this.state.gender;
        if (gender === undefined) {
            Toast.show({content: "\"请选择性别\"", icon: 'fail'})
            return;
        }


        const param = {
            name: userName, gender: gender, birthdate: dateParam//"2024-08-29T01:47"
        }
        const that = this;
        try {
            // request
            fetch('https://sss.work/calculate-fortune', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify(param)
            })
                .then(response => response.json())
                .then(data => {
                    this.parseData(data, that);
                    // console.log("chen:" + JSON.stringify(data))
                });
        } catch (error) {
            console.error(error.message);
        }
    }

    renderForm() {
        return <div  style={{visibility: this.state.showForm ? "visible" : "none"}}>

            <div className="form-title">运势根据五行八字生成</div>
            <div className="luck_font_title_1">请填写信息</div>
            {(!this.state.showLuckData) && this.wrapperCard(
                <div>
                    <div
                        name="login"
                        style={{backgroundColor: "transparent"}}

                        // onSubmit={(values) => this.submitSucceed(values)}
                        // onFinish={(values) => this.submitSucceed(values)}
                        // onFinishFailed={(values, errors) => this.submitSucceed(values)}
                    >
                        <div className="page-section">
                            <div className="h_line"/>
                        </div>
                        <div label="姓名" name="userName"
                                   align="left"
                                   className="luck-input-item"
                        >

                            <div >姓名：</div>
                            <Input className="luck-input-area"
                                   value={this.state.name}
                                   onChange={(v) => {
                                       this.setState({name: v})
                                       this.checkData();
                                   }} placeholder="请输入姓名"/>
                        </div>

                        <div className="page-section">
                            <div className="h_line"/>
                        </div>
                        <div label="性别" name="gender"
                                   align="left"
                                   className="luck-input-item"
                        >
                            <div >性别：</div>

                            <Radio.Group
                                value={this.state.gender}
                                onChange={(v) => {
                                    this.setState({gender: v})
                                    this.checkData();
                                }}>
                                <Radio value="male">男</Radio>
                                <Radio value="female">女</Radio>
                            </Radio.Group>
                        </div>


                        <div className="page-section">
                            <div className="h_line"/>
                        </div>
                        <div label="生日" name="birthdate"
                                   align="left"
                                   className="luck-navigator-arrow"

                        >
                            <div className="luck-birthday">生日：</div>
                            <input style={{fontSize: this.state.birthdateInitValue === "" ? 14 : 10}}
                                   className="luck-input-area" ref={this.inputRef} id="demo4" type="text"
                                   data-confirm="true" data-toid-date="date3" data-toid-hour="hour3"
                                   data-toid-minute="minute3" name="input_date" data-hour="" placeholder="选择出生日期"
                                   readOnly="readonly"/>
                            {/*<input className="input-area" ref={this.inputRef}  id="demo3" type="text" data-confirm="true" data-toid-date="date1" data-toid-hour="hour1"*/}
                            {/*       data-hour="7" placeholder="选择出生日期" readOnly="readonly"/>*/}

                            {/*<div*/}
                            {/*    className="input-area"*/}
                            {/*    onClick={() => this.showDatePicker()}*/}
                            {/*>*/}
                            {/*    {this.state.birthdate}*/}

                            {/*</div>*/}

                        </div>
                        <div className="page-section">
                            <div className="h_line"/>
                        </div>
                        <div
                            // style={{
                            //     height: 48,
                            //     display: 'flex',
                            //     justifyContent: "center",
                            //     alignItems: "center",
                            //     width: '100%',
                            // }}
                        >
                            <div onClick={() => this.submitSucceed({})} style={{
                                backgroundColor: this.state.isDataOk ? "rgba(179, 38, 30, 1)" : "rgba(147, 143, 153, 1)",
                                borderRadius: 10
                            }} className={this.state.isDataOk ? "luckSubmitButton" : "luckSubmitButtonDisable"}>
                                立刻查看
                            </div>

                        </div>
                    </div>
                    {/*<DatePicker*/}
                    {/*    title="选择出生日期"*/}
                    {/*    visible={this.state.datePickerVisible}*/}
                    {/*    type="datetime"*/}
                    {/*    startDate={new Date(1900, 1, 1)}*/}
                    {/*    endDate={new Date()}*/}
                    {/*    defaultValue={new Date()}*/}
                    {/*    onClose={() => this.hideDatePicker()}*/}
                    {/*    onConfirm={(options, values) => this.datePickConfirm(values, options, this)}*/}
                    {/*/>*/}

                    <input type="hidden" id="date3" name="date"/>
                    <input type="hidden" id="hour3" name="hour"/>
                    <input type="hidden" id="minute3" name="minute"/>

                </div>
                , 300, 0, "rgba(255, 255, 255, 1)")}
        </div>;
    }

    wrapperCard(child, width = 300, marginTop = 48, backgroundColor = "rgba(255, 255, 255, 0.6)") {
        return <div
            style={{
                marginTop: marginTop, background: "transparent", justifyContent: "center"
            }}
        >
            <div
                style={{
                    display: "flex", background: "transparent", alignItems: "center", justifyContent: "center", flex: 1
                }}
            >
                <Card title="" bordered={false} style={{background: backgroundColor, width: width}}>
                    {child}
                </Card>
            </div>
        </div>;
    }

    renderTopCard() {
        return this.wrapperCard(
            <div>
                <div id="daily-fortune-info"></div>
                <h2 className="luck-title">当下财运</h2>
                <div className="row-wrapper">
                    <p id="lucky-description">谈判、工作、打牌均可参考，两小时更新一次</p>
                </div>

                <div className="row-wrapper-content">
                    <div>旺财方位：<span id="lucky-dirction">{this.state.luckDirection}</span></div>
                </div>
                <div className="row-wrapper-content">
                    <div>旺财颜色：<span id="lucky-color" style={{color: "#d81b60"}}>{this.state.luckColor}</span></div>
                </div>

                <div className="row-wrapper-content">
                    <div>旺财数字：<span id="lucky-time">{this.state.luckNum}</span></div>
                </div>

                {!this.state.showForm && (!this.state.disableForm) ?
                    <div className="show-form-btn" onClick={() => {
                        this.setState({showForm: true})
                    }}>
                        定制化个人财运提醒
                    </div> : <div/>}

                {this.state.showLuck ? <Button className="button-wealth-secret" size="large" onClick={() => {
                    this.setState({showLuck: false, showLuckData: true})
                }}>
                    我的财富密码
                </Button> : <div/>}


            </div>);
    }


    renderDetail() {
        if (Object.keys(this.state.data).length === 0) {
            return <div></div>
        }

        const scoreStyle = {transform: `rotate(${this.state.score + "deg"})`};


        let goodsItems=[];
        if( Object.keys(this.state.goodsItems).length >0){
            goodsItems.push(this.state.goodsItems);
        }
        return (
            <div className="container">
                <div className="main">

                    <div className="luck-title">已根据五行八字生成</div>
                    <div className="luck_font_title_1">你的今日运势</div>


                    <div className="score-container-outer">
                        <div className="row_layout">
                            <div className="circled-text"> 今</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 日</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 运</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 势</div>
                        </div>


                        <div className="score-container">
                            <div className="score-content">
                                <Image className="score-image-bg"
                                       src="https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/score0.png"/>
                                <Image className="score-image" style={scoreStyle}
                                       src="https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/score100.png"/>
                                <div className="score-text">{this.state.displayScore}</div>
                                <div className="score-label">综合分数</div>
                                <div className="score-mask"></div>

                            </div>
                        </div>

                        <div className="luck-container">
                            <div className="row-wrapper-content">
                                <div>旺财方位：{this.state.luckDirection}

                            </div>
                            <div className="row-wrapper-content">
                                <div>旺运颜色：{this.state.luckColor}
                                </div>
                            </div>
                            <div className="row-wrapper-content">
                                <div>幸运数字：{this.state.luckNum}</div>
                            </div>
                        </div>
                        </div>

                    </div>

                </div>

                <div className="wuxing-container-outer">
                    <div className="wuxing-container">
                        <div className="wuxing_row_layout">
                            <div className="circled-text"> 五</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 行</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 建</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 议</div>
                        </div>

                        <div className="pie_container">
                            <ReactECharts
                                option={this.getOption()}
                                style={{height: 200}}
                            />
                        </div>


                        <div className="wuxing-percent-row">

                            {this.state.wuxing.map(it => (

                                <div className="wuxing-percent-item"
                                     style={{borderLeft: "2px solid #86909C", paddingLeft: 4}} key={it.id}>

                                    <div style={{color: `${it.color}`, textAlign: "left"}}>{it.name}</div>
                                    <div>
                                        <div style={{color: `${it.color}`}}>{it.value}</div>
                                        <div style={{marginLleft: 3, fontSize: 15, color: "#86909C"}}>%</div>
                                    </div>
                                </div>

                            ))}


                        </div>


                        <div className="row-wrapper-content">
                                <div>{this.state.wuxing_summary_string}</div>
                        </div>
                        <div className="row-wrapper-content">
                            <div>增强财运建议补充：{this.state.caiyun?.toString()}
                            </div>
                        </div>
                        <div className="row-wrapper-content">
                            <div>增强学业智慧建议补充：{this.state.zhihui?.toString()}
                            </div>
                        </div>
                        <div className="row-wrapper-content">
                            <div>增强平安健康建议补充：{this.state.pingan?.toString()}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="wuxing-container-outer">
                    <div className="wuxing-container">
                        <div className="wuxing_row_layout"
                             style={{backgroundColor: "white", marginTop: "20px", height: "60px"}}>
                            <div className="circled-text"> 好</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 物</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 推</div>
                            <div className="v-line"></div>
                            <div className="circled-text">荐</div>
                        </div>


                        <div className="goods-row">

                            {

                                goodsItems.map(it => {
                                    return (<div className="goods-item" key={it.id} onClick={() => {
                                        window.open(`https://wtwt.cn/product-category/${this.state.wuxing_missing_code}`)
                                    }}>
                                        <img className="goods-img"
                                             src={it.image.src}
                                             alt="img"/>
                                        <span className="goods-text">{it.name}</span>
                                    </div>);
                                })
                            }

                        </div>


                    </div>
                </div>


            </div>
        );
    }
}

export default Luck;
