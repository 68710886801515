import {createRef, PureComponent} from "react";
import '@nutui/nutui-react/dist/style.css'
import {Card} from "antd-mobile";
import html2canvas from 'html2canvas';

import {Button, DatePicker, Form, Input, Radio, TextArea, Toast} from '@nutui/nutui-react'
import {caiyunqifu, prayBg, screenCaptureBg} from "./imagebasecode"

import './index.css';
import praywords from './praywords.json';
import icDonateChecked from './ic_donate_checked.png';

const loadDPrayWords = JSON.parse(JSON.stringify(praywords));


const PRAY = "祈福";


class Pray extends PureComponent {

    state = {
        showForm: false,
        dateRef: null,
        datePickerVisible: false,
        passCode: "",
        birthdate: "",
        birthdateDisplay: "",
        phone:"",
        name: "",
        gender: "",
        address: "",
        isDataOK: false,
        renderPay: false,
        productData: {},
        productID: "",
        showVideo: false,
        prayPlace: "",
        prayId: "",
        prayWords: [],
        prayWord: "",
        items: [
            {
                id: 1,
                dsp: ["大殿供奉365天", "桐柏宫符一张", "燃香一次"],
                name: "普通版",
                feature: "大殿供365天",
                price: 365,
                img: "https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/main.png",
                checked: false
            },
            {
                id: 2,
                dsp: ["大殿供奉365天", "桐柏宫符一张", "燃香一次", "祈福御守子符随身守护365天", "祈福御守开光服务一次"],
                name: "子母御守标准版",
                feature: "查每日运势",
                price: 188,
                img: "https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/slave.png",
                checked: true
            },
            {
                id: 3,
                dsp:  ["大殿供奉365天", "桐柏宫符一张", "燃香一次", "祈福御守子符随身守护365天", "祈福御守开光服务一次","开光任我行五行运势手串1个"],
                name: "五行子母御守高级版",
                feature: "旺五行能量",
                price: 188,
                img: "https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/item.png",
                checked: false
            },
        ],
        showDsp:["大殿供奉365天", "桐柏宫符一张", "燃香一次", "祈福御守子符随身守护365天", "祈福御守开光服务一次"],
        isPayButtonOk: true,
        showShare:false,
        prayDate:"",

    };


    ref = createRef();

    onItemClick(e) {
        const id = parseInt(e);
        console.log(id);
        let array = [...this.state.items];
        let isDataOK = false;
        let showDsp=[];
        for (let i = 0; i < array.length; i++) {

            if (array[i].id === id) {
                array[i].checked = true;
                console.log(array[i]);
                showDsp=  array[i].dsp;
            }else{
                array[i].checked =false;
            }
            if (array[i].checked) {
                isDataOK = true;
            }
        }
        this.setState({items: array, isPayButtonOk: isDataOK,showDsp:showDsp});
        console.log(array);

    }

    setOkFalse() {
        if (this.state.isDataOK) {
            this.setState({isDataOK: false});
        }
    }

    onNameChange = (v) => {
        this.setState({name: v});
        this.checkData();
    }

    onPhoneChange = (v) => {
        this.setState({phone: v});
    }

    validatePhoneNumber(phoneNumber) {
        var regex = /^1[3456789]\d{9}$/;
        return regex.test(phoneNumber);
    }

    onAddressChange = (v) => {
        this.setState({address: v});
        this.checkData();
    }
    onGenderChange = (v) => {

        this.setState({gender: v});
        this.checkData();
        // console.log(this.state)
    }

    activeOwner = () => {
        this.setState({showForm: true})

    }

    checkData = () => {
        // console.log(this.state);

        setTimeout(() => {
            if (this.state.address === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.birthdate === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.name === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.gender === "") {
                this.setOkFalse();
                return;
            }

            this.setState({
                isDataOK: true
            });

        }, 100);

    }

    submitSucceed = (values) => {
        // console.log(values);

        if (!this.state.isDataOK) return;


        const userName = values.userName.toString();
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (!reg.test(userName)) {
            Toast.show({content: "\"请输入正确的姓名\"", icon: 'fail'})
            return;
        }

        this.setState({renderPay: true});

    }


    showDatePicker = () => {
        // console.log("chen:" + this.state.datePickerVisible);
        this.setState({datePickerVisible: true});

    }

    hideDatePicker = () => {
        this.setState({datePickerVisible: false});

    }

    componentDidMount() {
        document.title = PRAY;


    }

    render() {

        // return this.renderShare();
        if(this.state.showShare){
            return this.renderShare();
        }


        if (this.state.prayPlace === "") {
            return this.renderEnter();
        }

        if (this.state.renderPay) {
            return this.renderPay();
        }



        return (

            <div className="pray-container">

                <div className="main">
                    <div className="title1">{PRAY}</div>

                    {this.state.showForm && this.renderForm()}
                </div>

            </div>

        );

    }


    onPrayWordChange = (v) => {
        console.log(v.target.value)
        this.setState({prayWord:v.target.value});
        this.checkData();
    }


    renderForm() {
        return <div style={{visibility: this.state.showForm ? "visible" : "hidden"}}>
            <div className="pray-title">{this.state.prayPlace}</div>
            <div className="pray-font_title_1">请填写祈福愿望</div>
            {this.wrapperCard(
                <div>

                    <div className="ability_container">
                        <textarea rows="3" className="pray-textarea-container" onChange={this.onPrayWordChange} value={this.state.prayWord}
                                  placeholder="许下或选择您的愿望..."/>

                        <div className="pray_row">

                            {this.state.prayWords.map(it => {

                                const {id, title, desp} = it;

                                return (<div className="pray-ability_item" key={id}
                                             onClick={() => this.setState({prayWord: desp})}
                                >
                                    <div>
                                        {title}
                                    </div>
                                </div>);

                            })}

                        </div>


                    </div>


                    <Form
                        name="login"
                        style={{backgroundColor: "transparent"}}
                        footer={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    width: '100%',
                                }}
                            >
                                <Button disabled={!this.state.isDataOK} style={{
                                    width: "100%",
                                    color: "white",
                                    backgroundColor: this.state.isDataOK ? "rgba(179, 38, 30, 1)" : "#CCCCCC"
                                }}
                                        className={"submitButton"}
                                        nativeType={this.state.isDataOK ? "submit" : ""}>
                                    下一步
                                </Button>

                            </div>
                        }
                        // onSubmit={(values) => this.submitSucceed(values)}
                        onFinish={(values) => this.submitSucceed(values)}
                        // onFinishFailed={(values, errors) => this.submitSucceed(values)}
                    >




                        <Form.Item label="姓名：" name="userName"
                                   className="nut-cell-title"
                                   align="center"
                        >
                            <Input className="input-area" placeholder="请输入姓名" onChange={this.onNameChange}/>
                        </Form.Item>


                        <Form.Item label="性别：" name="gender"

                                   align="center"
                                   initialValue={this.state.gender}
                        >
                            <Radio.Group onChange={this.onGenderChange}>
                                <Radio value="male">男</Radio>
                                <Radio value="female">女</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="生日：" name="birthdate"
                                   className="navigator-arrow"

                                   align="center"
                                   initialValue=""

                        >
                            <div className={"input-area"}
                                 onClick={() => this.showDatePicker()}
                                 readOnly
                            >
                                {this.state.birthdate === "" ? "选择出生时间" : this.state.birthdateDisplay}
                            </div>


                        </Form.Item>

                        <Form.Item label="居住地址：" name="address"
                                   className="nut-cell-title"
                                   align="center"
                        >
                            <TextArea className="input-area" placeholder="居住地址：法会上表需要，输入现居地址"
                                      onChange={this.onAddressChange}/>
                        </Form.Item>

                    </Form>
                    <DatePicker
                        title="选择出生日期"
                        visible={this.state.datePickerVisible}
                        type="datetime"
                        startDate={new Date(1900, 1, 1)}
                        endDate={new Date()}
                        defaultValue={new Date()}
                        onClose={() => this.hideDatePicker()}
                        onConfirm={(options, values) => this.datePickConfirm(values, options, this)}
                    />


                </div>
                , 355, 20, "rgba(255, 255, 255, 1)")}
        </div>;
    }

    wrapperCard(child, width = 300, marginTop = 48, backgroundColor = "rgba(255, 255, 255, 0.6)") {
        return <div
            style={{
                marginTop: marginTop, background: "transparent", justifyContent: "center", marginBottom: 20
            }}
        >
            <div
                style={{
                    display: "flex", background: "transparent", alignItems: "center", justifyContent: "center", flex: 1
                }}
            >
                <Card title="" bordered={false} style={{background: backgroundColor, width: width}}>
                    {child}
                </Card>
            </div>
        </div>;
    }

    datePickConfirm = async (values, options, that) => {
        // console.log("chen" + JSON.stringify(values))
        const date = values.slice(0, 3).join('-')
        const time = values.slice(3).join(':')
        const dd = `${date}T${time}`;
        const birthdateDisplay = `${values[0]}年${values[1]}月${values[2]}日${values[3]}点${values[3]}分`;
        this.setState({birthdate: dd, birthdateDisplay});


        setTimeout(() => {
            this.checkData();
            // console.log(dd);
            // console.log(this.state.birthdate);
        }, 20);


    }


    parseData(data, that) {


        this.setState({productData: data});
        if (Object.keys(data).length === 3) {


        } else {
            // navigate('/checkNotFound', { replace: false });
        }
    }

    onEnter(it) {

        // console.log(loadDPrayWords);
        // console.log(this.state.prayId)
        const id = it.id;
        const prayWords = loadDPrayWords.filter(it => it.id === id)[0].prayWords;

        this.setState({prayId: id, prayPlace: "桐柏宫 ·" + it.title.substring(0, 3), showForm: true, prayWords})

        setTimeout(() => {
            console.log(this.state);
        }, 50)


    }


    shareWechat(){
        const that=this;
        html2canvas(this.ref.current).then(canvas => {
            const image = canvas.toDataURL('image/png');
            // 可以下载或者上传到服务器
            that.downloadImage(image);
        });
    }

    downloadImage(dataUrl) {
        const link = document.createElement('a');
        link.download = 'share-image.png';
        link.href = dataUrl;
        link.click();
    }

    sendPray(){

        const item=this.state.items.find(it=>it.checked);

        const now = new Date();
        const prayDate=this.formatDate(now);

       if(this.state.phone!==""&&!this.validatePhoneNumber(this.state.phone)) {
           Toast.show({content: "\"手机号\"错误,请核验手机号！", icon: 'fail'})
           return;
       }

        const data = {
            title: `${this.state.name}祈福`,
            content: "content",
            excerpt: "excerpt",
            acf: {
                name: this.state.name,
                gender: this.state.gender,
                date:prayDate ,
                birthday: this.state.birthdate ,
                address: this.state.address,
                prayWord: this.state.prayWord,
                place: this.state.prayPlace,
                phone: this.state.phone,
                option: item.name
            }
        };
        const username = "sss";
        const password = "EbM7 8uKR Ntk2 8Zqd yKmH X93b";
        const b64Code = btoa(`${username}:${password}`);  // Base64 encode the credentials

        const that=this;

        try {
            // request
            fetch('https://wtwt.cn/wp-json/wp/v2/pray', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                        'Authorization': `Basic ${b64Code}`,

                }, body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    // console.log(data);
                    that.setState({showShare:true,prayDate:prayDate});

                    setTimeout(()=>{
                        that.shareWechat();
                    },2000);
                    if (data.hasOwnProperty("code")) {
                        // console.log("chen1:" + JSON.stringify(data))
                        Toast.show({content: "\"祈福失败\"错误", icon: 'fail'})
                    } else {
                        Toast.show({content: "\"祈福成功\"", icon: 'success'})
                        // console.log("chen2:" + JSON.stringify(data))

                    }
                    // if(data.code==="passcode_incorrect"){
                    //
                    // }

                    // this.parseData(data, that);
                    // console.log("chen:" + JSON.stringify(data))
                });
        } catch (error) {
            console.error(error.message);
        }

    }

     formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    renderShare(){

        return (

            <div className="share-main" ref={this.ref}>

                <img  className="share-img" src={screenCaptureBg} alt=""/>

                <div   className="share-bg">

                    <div className="share-row_layout">


                        <img  className="share-img-title" src={caiyunqifu} alt=""/>

                    </div>
                    <div className="share-donater">
                        {this.state.prayDate}
                    </div>    <div className="share-donater">
                        · 桐柏宫信众{this.state.name} ·
                    </div>

                    <div className="share-pray-word">
                        {this.state.prayWord}
                    </div>

                    <img className="imag-share-qr" alt=""
                           src={prayBg}/>
                    <div className="share-donater">扫一扫，我也祈福</div>
                    {/*<view className="spacer"/>*/}

                </div>
            </div>

        );

    }


    renderPay() {

        return (


            <div className="pray-pay-container">
                <div className="pray-main">

                    <text className="pray-title">{this.state.prayPlace}</text>
                    <div className="pray-font_title_1">恭请法物完成祈福</div>
                    <div className="pray-chinese-style-frame-outer">
                        <div className="pray-chinese-style-frame">

                            <div className="pray-row_layout">
                                <div className="pray-circled-text"> 财
                                </div>
                                <div className="v-line"></div>
                                <div className="pray-circled-text"> 运</div>
                                <div className="v-line"></div>
                                <div className="pray-circled-text"> 祈</div>
                                <div className="v-line"></div>
                                <div className="pray-circled-text"> 福</div>
                            </div>

                            <div className="pray-word">
                                {this.state.prayWord}
                            </div>


                            <div className="spacer"/>
                            <div className="donater">
                                · 桐柏宫信众{this.state.name} ·
                            </div>


                        </div>


                    </div>

                    <div className="donate-item-row">

                        {this.state.items.map(it =>
                            <div className="donate-item" key={it.id} onClick={(i) => this.onItemClick(it.id)}>
                                <div className="item-image-container">
                                    <img style={{height:"80%"}} className="item-image" src={it.img} alt=""/>
                                    {it.checked && <img className="item-check-icon" src={icDonateChecked} alt=""/>}
                                </div>



                                <div className="donate-item-div">{it.name}</div>
                                {/*<div className="donate-item-div">{it.feature}</div>*/}
                            </div>
                        )}

                    </div>

                    {this.state.showDsp.map((it,index)=>( <div className="donate-item-text">{index+1}.{it}</div>))}

                    <div    className="pay-phone">
                        <div
                                   style={{color: "white"}}

                        >     </div>
                            <Input className="input-area" value={this.state.phone} placeholder="如需购买请留下手机号" onChange={this.onPhoneChange}/>

                    </div>

                    <Button disabled={!this.state.isPayButtonOk}
                            style={{
                                width: "100%",
                                color: "white",
                                backgroundColor: this.state.isPayButtonOk ? "rgba(179, 38, 30, 1)" : "#CCCCCC"
                            }}
                            className={"submitButton"}

                            nativeType={this.state.isPayButtonOk ? "submit" : ""}
                            onClick={() => this.sendPray()}
                    >
                        祈福
                    </Button>

                </div>
            </div>
        );
    }

    renderEnter() {

        return (
            <div className="pray-enter-container">
                <div className="main">

                    <div className="title font_title_1">桐柏宫大殿祈福</div>

                    <div className="outer-container">
                        <div className="blur-background"></div>

                        <div className="ability_container">
                            <div className="sub_title">
                                本次祈福将在桐柏宫各殿内挂符365天<br/>每日获得心愿加持，愿你所求如愿
                            </div>

                            {
                                loadDPrayWords.map(it =>
                                    <div className="ability_item" key={it.id} onClick={() => this.onEnter(it)}>
                                        {it.title}
                                    </div>
                                )
                            }


                            <div className="ability_container_bottom"/>
                        </div>
                    </div>
                </div>
            </div>

        );
    }


}

export default Pray;


