import {PureComponent} from "react";
import '@nutui/nutui-react/dist/style.css'


import './index.css';
import BlurContainer from "../BlurContainer";


class WatchVideo extends PureComponent {
    state={
        title:"",
        videoUrl:""
    }

   componentDidMount() {
       const params = new URLSearchParams(window.location.search);
       const title = params.get('title');
       const url = params.get('url');

       console.log(params)
       console.log(url)
       this.setState({
           title,
           videoUrl:url,
       });
   }

   render() {
        return (
            <BlurContainer>
                <div className="video-container">
                    <video className="video"  controls={true}  autoPlay muted={true} loop={true}>
                        <source src={this.state.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </BlurContainer>
        );
   }


}

export default WatchVideo;
