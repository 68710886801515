import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchProductInfo } from './lightAPI'

const initialState = {
  status:"idle",
  productData:{}
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchProductInfoAsync = createAsyncThunk(
  'light/fetchData',
  async id => {
    const response = await fetchProductInfo(id)
    // The value we return becomes the `fulfilled` action payload
      console.log("response");
      console.log(response);
    return response.data
  }
)

export const lightSlice = createSlice({
  name: 'light',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
     setProductData: (state, action)=> {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
         console.log("chen set data")
         console.log(action.payload)
         console.log(action)
         console.log("chen set data")
         console.log(state)
      // state.productData={...action.payload}
      state.productData=action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(fetchProductInfoAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchProductInfoAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.productData= action.payload
      })
  }
})

export const { setProductData } = lightSlice.actions



export default lightSlice.reducer
