import {PureComponent} from "react";
import '@nutui/nutui-react/dist/style.css'


import './index.css';


class CheckNotFound extends PureComponent {
    state={
        productID:"",

    }

   componentDidMount() {
       document.title = "桐柏宫法物加持核验";
       const params = new URLSearchParams(window.location.search);
       const id = params.get('id');

       console.log(params)
       if(id!==undefined){
           this.setState({
               productID:id
           });
       }

   }

    onTextChange=(v)=>{
        this.setState({productID:v});
    }



   renderCheck(){
       return (
           <div className="check-notfound-container">
               <div className="notfound-container">
                   <div className="img-notfound"></div>
                   <div className="text-notfound">对不起，该产品没有找到加持记录</div>
                   <div className="text-notfound">请购买桐柏宫加持法宝</div>
                   <div className="check-btn"
                        onClick={()=>{
                       console.log("ccc")
                       window.open("https://wtwt.cn/");

                       console.log("ccc")
                   }}>前往商城</div>
               </div>

           </div>

       );
   }



}

export default CheckNotFound;
